import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));

import { useIsMobile } from "../../hooks";
import { SUPPORTED_REGIONS } from "../../../shared/enums";

import heroImage1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-01.jpg";
import heroImageWebp1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-01.jpg?as=webp";
import heroImageSm1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-01.jpg";
import heroImageWebpSm1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-01.jpg?as=webp";

import heroImage2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-02.jpg";
import heroImageWebp2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-02.jpg?as=webp";
import heroImageSm2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-02.jpg";
import heroImageWebpSm2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-02.jpg?as=webp";

import heroImage3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-03.jpg";
import heroImageWebp3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-03.jpg?as=webp";
import heroImageSm3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-03.jpg";
import heroImageWebpSm3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-03.jpg?as=webp";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const regionalPrices = {
  US: {
    price: 244,
    oldPrice: "$408-$448",
  },
  CA: {
    price: 329,
    oldPrice: "$550-$599",
  },
  AU: {
    price: 384,
    oldPrice: "$628-$683",
  },
  SG: {
    price: 333,
    oldPrice: "$549-$604",
  },
  GB: {
    price: null,
    oldPrice: null,
  },
};

const HeroAirSliderChristmas = ({ localization, handleBuyNow }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;
  const { price, oldPrice } = regionalPrices[regional];
  const isMobile = useIsMobile(769);
  const [isSliderInitialized, setIsSliderInitialized] = useState(false);
  const [progress, setProgress] = useState(-100);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleBeforeChange = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    if (!isSliderInitialized) return;

    setProgress(-100);

    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 0 ? -100 : prev + 1));
    }, 60);
    return () => clearInterval(interval);
  }, [isSliderInitialized, currentSlide]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    centerMode: true,
    centerPadding: "35px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          centerPadding: "15px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
        },
      },
    ],
    onInit: () => {
      setIsSliderInitialized(true);
    },
    beforeChange: (_, nextIdx) => {
      handleBeforeChange(nextIdx);
    },
  };

  return (
    <section className="hero-refresh" style={{ "--progress": `${progress}%` }}>
      <div className="container">
        <Slider className="hero-refresh__slider" {...settings}>
          <div className="hero-refresh__item hero-item">
            <ImageLoader
              className="hero-item__image img-relative"
              placeholderAspectRatio={1320 / 617}
              image={heroImage1}
              webp={heroImageWebp1}
              smallImage={heroImageSm1}
              smallWebp={heroImageWebpSm1}
              alt="The Holiday Sale"
              noLazyLoad
            />
            {price && (
              <strong className="hero-item__badge">
                <span>Starting at</span>
                <strong>${price}</strong>
                <del>{oldPrice}</del>
              </strong>
            )}
            <div className="hero-item__body">
              <div className="hero-item__content">
                <strong className="hero-item__subtitle">
                  {isMobile
                    ? "The Holiday Sale."
                    : "A Mila for every air breather."}
                </strong>
                <h1 className="hero-item__title">
                  {isMobile ? (
                    <>
                      A Mila for every <br /> air breather.
                    </>
                  ) : (
                    "The Holiday Sale."
                  )}
                </h1>
                <p className="hero-item__description">
                  Celebrate every air breather on your list and save up to 30%
                  on the Mila with Critter Cuddler, Rookie Parent or Mama-to-be
                  filter.
                </p>
              </div>
              <a
                href="https://shop.milacares.com"
                className="hero-item__button button button--white"
              >
                Buy Now
              </a>
            </div>
          </div>

          <div className="hero-refresh__item hero-item hero-item--02">
            <ImageLoader
              className="hero-item__image img-relative"
              placeholderAspectRatio={1320 / 617}
              image={heroImage2}
              webp={heroImageWebp2}
              smallImage={heroImageSm2}
              smallWebp={heroImageWebpSm2}
              alt="The Holiday Sale"
              noLazyLoad
            />

            <div className="hero-item__body">
              <div className="hero-item__content">
                <strong className="hero-item__subtitle">
                  CELEBRATE WITH MILA
                </strong>
                <h1 className="hero-item__title">Joy to air.</h1>
                <p className="hero-item__description">
                  {isMobile
                    ? "Give and get the gift that’s always in season with the award-winning air purifiers on everyone’s list. "
                    : "Give and get the gift that’s always in season, and celebrate with the award-winning air purifiers on everyone’s list."}
                </p>
              </div>
              <button
                onClick={(e) => handleBuyNow(e)}
                className="hero-item__button button button--white"
              >
                Buy Now
              </button>
            </div>
          </div>
          <div className="hero-refresh__item hero-item hero-item--03">
            <ImageLoader
              className="hero-item__image img-relative"
              placeholderAspectRatio={1320 / 617}
              image={heroImage3}
              webp={heroImageWebp3}
              smallImage={heroImageSm3}
              smallWebp={heroImageWebpSm3}
              alt="The Holiday Sale"
              noLazyLoad
            />

            <div className="hero-item__body">
              <div className="hero-item__content">
                <strong className="hero-item__subtitle">NOW IN 2 SIZES</strong>
                <h1 className="hero-item__title">
                  Honey, we <br />
                  shrunk the Mila.
                </h1>
                <p className="hero-item__description">
                  Introducing Mila Air Mini: Big power for your smaller
                  spaces—so no room gets left behind.
                </p>
              </div>
              <a
                href="https://airmini.milacares.com"
                className="hero-item__button button button--white"
              >
                Buy Now
              </a>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default HeroAirSliderChristmas;
